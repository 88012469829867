.avatar {
    border-radius: 12px;
    max-width: 300px;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload_btn {
    margin: 20px 0px;
}
.upload_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn_container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.obfuscate_text {
    min-height: 300px !important;
}
.resource_input {
    max-width: 300px;
}
.footer_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
