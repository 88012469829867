@import 'styles/_global.scss';
.product_card {
    border: 1px solid $primary-dark;
    &:hover {
        border: 1px solid $primary-dark;
    }
}
.cover_image {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product_name {
    font-size: 18px !important;
    margin: 0px !important;
}
.product_version {
    font-size: 14px !important;
    text-align: right;
    margin: 0px !important;
}
.product_description {
    font-size: 14px !important;
    text-align: left;
    font-weight: 400 !important;
    margin: 0px 0px 16px 0px !important;
    &_sent {
        @extend .product_description;
        color: #4ef542 !important;
    }
}
.new_version_alert {
    font-size: 14px !important;
    text-align: left;
    font-weight: 400 !important;
    margin: 0px !important;
}
.action_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.warning_text {
    font-size: 20px !important;
    font-weight: bold !important;
    font-family: 'Kanit' !important;
    &_highlight {
        @extend .warning_text;
        color: red !important;
    }
}
.warning_title {
    font-size: 26px !important;
    font-weight: bold !important;
    font-family: 'Kanit' !important;
}
