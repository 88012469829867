@import 'styles/_global.scss';
.wrapper {
    height: calc(100vh - 128px);
}
.left_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: -20%;
}
.img_banner {
    width: 75%;
    border-radius: 160px;
    z-index: 1;
    position: absolute;
    transform: rotate(8deg);
    top: 9%;
    right: 9%;
    @include media('<991px') {
        max-width: 50%;
        top: 9%;
        right: 22%;
    }
}
@keyframes fly {
    0%,
    to {
        transform: translateY(5%);
    }

    50% {
        transform: translateY(0);
    }
}
.img_decoration {
    width: 100%;
    height: auto;
    z-index: 2;
    position: absolute;
    animation: fly 6s cubic-bezier(0.75, 0.02, 0.31, 0.87) infinite;
    left: 3%;
    top: 4%;
    @include media('<991px') {
        max-width: 80%;
        top: -8%;
        left: 12%;
    }
}
.img_container {
    top: 0;
    @include media('<990px') {
        top: -280px;
    }
    @include media('<600px') {
        top: -200px;
    }
}
