@import 'styles/_global.scss';
.header {
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s, opacity 0.5s ease;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    position: fixed;
    width: 100%;
    height: 78px;
    z-index: 999;
    box-shadow: 1px 2px 24px -5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 2px 24px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 2px 24px -5px rgba(0, 0, 0, 0.1);
    &_default {
        @extend .header;
        background-color: $primary-bg;
    }
    &_dark {
        @extend .header;
        background-color: $primary-dark-bg;
    }
}
.logo_container {
    display: flex;
    align-items: center;
}

.menu_button:first-child {
    margin-right: 4px;
}

.menu_button {
    margin-right: 4px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
}

.menu_button:last-child {
    margin-left: 4px;
}
.menu_icon {
    max-width: 24px;
    filter: invert(100%) sepia(33%) saturate(2%) hue-rotate(300deg) brightness(108%) contrast(100%);
    &_dark {
        @extend .menu_icon;
        filter: invert(7%) sepia(30%) saturate(2958%) hue-rotate(196deg) brightness(94%) contrast(100%);
    }
}
.right_menu_group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.profile_picture {
    width: 36px;
    min-height: 32px;
    object-fit: cover;
    border-radius: 100%;
    border: 2px solid $primary-bg;
    margin: 0px 4px;
    cursor: pointer;
}
.desktop_menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.mobile_menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media('>767px') {
        display: none;
    }
}
.mobile_config_menu {
    display: flex;
    padding: 20px 10px;
}
.collapse {
    & div div {
        align-items: center !important;
    }
}
.cart_count {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0px 4px;
    border-radius: 8px;
    top: -15px;
    font-size: 10px;
    left: 24px;
    color: $text-light;
    &_dark {
        @extend .cart_count;
        background-color: rgba(255, 255, 255, 0.8);
        color: $text-dark;
    }
}
