@import '~include-media/dist/include-media.scss';
$primary: #ffc524;
$primary-bg: #ebf0f0;
$primary-dark-bg: #071429;
$primary-dark: #ffc524;
$text-dark: #031025;
$text-light: #ffffff;
$darkmode_bg: #0c1e38;
$secondary-menu: #1a3f75;
$secondary: rgba(255, 227, 151, 0.5);
$secondary-dark: rgba(7, 20, 41, 0.3);
