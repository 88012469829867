.image_avatar {
    border-radius: 12px;
    max-width: 300px;
}
.image_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload_btn {
    margin: 20px 0px;
}
.upload_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn_container {
    display: flex;
    gap: 10px;
}
