@import 'styles/_global.scss';
.product_name_container {
    background-color: $primary;
    padding: 12px 20px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 20%;
    &_dark {
        @extend .product_name_container;
        background-color: $primary-dark-bg;
    }
}

.product_name {
    max-width: 70%;
    margin: 0px !important;
    color: $text-dark !important;
    &_dark {
        @extend .product_name;
        color: $primary !important;
    }
}
.pick_product_container {
    border: 1px solid $secondary-dark;
    padding: 12px 20px;
    border-radius: 4px;
    height: 50%;
    &_dark {
        @extend .pick_product_container;
        background-color: $primary-dark-bg;
    }
}

.pick_product {
    margin: 0px !important;
    margin-bottom: 10px !important;
}
.product_type {
    margin: 30px 0px;
    display: flex;
    flex-wrap: wrap;

    gap: 12px;
}
.like_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.like_count {
    font-size: 18px;
}
.like_icon {
    font-size: 18px;
    padding-right: 4px;
    cursor: pointer;
    &_dark {
        @extend .like_icon;
        color: $primary;
    }
}
.price_container {
    border: 1px solid $secondary-dark;
    border-radius: 4px;
    padding: 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_dark {
        @extend .price_container;
        background-color: $primary-dark-bg;
    }
}
.add_to_cart_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.price_partner {
    font-size: 14px;
    color: $text-dark;
    margin: 8px 0 0 0;

    &_dark {
        @extend .price_partner;
        color: $text-light;
    }
}
.price_text {
    margin: 0px !important;
    color: $primary;
    margin-bottom: 12px !important;
}
