@import "styles/_global.scss";
.user_menu_header {
    padding: 2px 8px;
    text-align: center;
    border-radius: 16px;
    font-weight: bold;
    color: $text-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.username {
    font-size: 26px;
    margin: 0px;
}
.tag {
    font-size: 16px;
}
.user_balance_wrapper {
    padding: 12px 10px;
    border-radius: 8px;
    border: 1px solid $secondary;
}
.balance_icon {
    max-width: 14px;
    margin-right: 10px;
    &_dark {
        @extend .balance_icon;
        filter: invert(86%) sepia(49%) saturate(1600%) hue-rotate(333deg) brightness(99%) contrast(107%);
    }
}
.user_balance_box {
    display: flex;
    align-items: center;
}
.user_point {
    font-size: 20px;
    font-weight: bold;
    color: $primary;
}
.user_menu_main {
    transition: 0s;
    background: $primary-bg;
    border: none;
    box-shadow: none;
    border-inline: none;
    &_dark {
        @extend .user_menu_main;
        background: $secondary-menu;
    }
}
.logout {
    &_dark {
        background: rgba(255, 255, 255, 0.3);
        @extend .user_menu_main;
        color: $primary;
    }
}
