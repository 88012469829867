@import 'styles/_global.scss';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-height: 100vh;
    padding-top: 64px;
    background-color: $primary-bg;
    &_dark {
        @extend .wrapper;
        background: $darkmode_bg;
    }
}
.container {
    padding: 50px 30px;
    max-width: 1200px;
    width: 100%;
    @include media('<991px') {
        width: 90vw;
        overflow: hidden;
    }
    &_admin {
        @extend .container;
        max-width: 100%;
    }
    &_loading {
        @extend .container;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.footer_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.footer_container {
    padding: 10px 30px;
    max-width: 1200px;
    width: 100%;
    @include media('<991px') {
        width: 90vw;
        overflow: hidden;
    }
    &_loading {
        @extend .container;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
