@import "styles/_global.scss";
.login_title {
    padding: 2px 8px;
    text-align: center;
    border-radius: 16px;
    color: $primary;
}
.login_content {
    max-width: 350px;
}
