.product_name {
    display: flex;
    align-items: center;
}
.product_img {
    max-width: 60px;
    max-height: 60px;
    border-radius: 6px;
    margin-right: 8px;
}
.total_price {
    text-align: right;
}
.redeem_container {
    display: flex;
    justify-content: flex-end;
}
