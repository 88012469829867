.images_container {
    display: flex;
    padding-top: 10px;
    gap: 8px;
}
.image {
    max-width: 183px;
    max-height: 108px;
    border-radius: 4px;
}
