@import 'styles/_global.scss';
.product_card {
    border: 1px solid $primary-dark;
    &:hover {
        border: 1px solid $primary-dark;
    }
}
.cover_image {
    border-radius: 16px;
    width: 100%;
    max-height: 145px;
    object-fit: cover;
}
.product_name {
    font-size: 14px !important;
}
.product_price {
    font-size: 14px !important;
    text-align: right;
    &_free {
        @extend .product_price;
        color: #4ef542 !important;
    }
}
.like_count {
    font-size: 18px;
}
.like_icon {
    font-size: 18px;
    padding-right: 4px;
    cursor: pointer;
    &_dark {
        @extend .like_icon;
        color: $primary;
    }
}
