.product_info {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-bottom: 15px;
}
.order {
    display: flex;
    align-items: center;
    gap: 12px;
}
