@font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Kanit';
}
a {
  color: inherit;
  text-decoration: none;
}
