@import 'styles/_global.scss';
.product_card {
    border: 1px solid $primary-dark;
    &:hover {
        border: 1px solid $primary-dark;
    }
}
.cover_image {
    border-radius: 16px;
    width: 100%;
    max-height: 145px;
    object-fit: cover;
}
.product_name {
    font-size: 14px !important;
}
.product_price {
    font-size: 14px !important;
    text-align: right;
    &_free {
        @extend .product_price;
        color: #4ef542 !important;
    }
}
.like_count {
    font-size: 18px;
}
.like_icon {
    font-size: 18px;
    padding-right: 4px;
    cursor: pointer;
    &_dark {
        @extend .like_icon;
        color: $primary;
    }
}

.carousel {
    height: 300px !important;
    &.ant-carousel .slick-list {
        height: 500px !important;
    }
    & ul {
        top: 108%;
        background-color: rgba(0, 0, 0, 0.15) !important;
        border-radius: 16px;
        padding: 10px 0px;
        left: 0 !important;
        right: 0 !important;
        margin: auto !important;
        width: 50%;
    }
    @include media('<991px') {
        top: 105% !important;
    }
    @include media('<600px') {
        top: 102% !important;
    }
    &_dark {
        @extend .carousel;
        & ul {
            background: rgba(255, 255, 255, 0.3) !important;
        }
    }
}
