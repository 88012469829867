@import 'styles/_global.scss';

.detail_container {
    padding: 20px !important;
    margin-top: 35px;

    &_dark {
        @extend .detail_container;
        background-color: $primary-dark-bg;
    }
}

.detail_header {
    margin: 0px !important;
    display: inline;
}

.detail {
    font-size: 16px;
    color: $text-dark;
    line-height: 6px;
    &_dark {
        @extend .detail;
        color: $text-light;
    }
}
