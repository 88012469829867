.menu {
    border: none !important;
    width: 100% !important;
    height: 100%;
    align-items: center;
}
.menu li {
    height: 100%;
    display: flex !important;
    align-items: center !important;
}
